@media print {
    .noprint {
        display: none !important;
    }
    
    .print-margim-bottom-30{
        margin-bottom: 30px;
    }
    .col-print-1, .col-print-2, .col-print-3, .col-print-4, .col-print-5, .col-print-6, .col-print-7, .col-print-8, .col-print-9, .col-print-10, .col-print-11, .col-print-12 {
        float: left;
    }
    .col-print-12 {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-print-11 {
            width: 91.66666667%;
            flex: 0 0 91.66666667%;
        max-width: 91.66666667%;
    }
    .col-print-10 {
            width: 83.33333333%;
            flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-print-9 {
            width: 75%;
            flex: 0 0 75%;
        max-width: 75%;
    }
    .col-print-8 {
            width: 66.66666667%;
            flex: 0 0 66.66666667%;
        max-width: 66.66666667%;
    }
    .col-print-7 {
            width: 58.33333333%;
            flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-print-6 {
            width: 50%;
            flex: 0 0 50%;
        max-width: 50%;
    }
    .col-print-5 {
            width: 41.66666667%;
            flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-print-4 {
            width: 33.33333333%;
            flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-print-3 {
            width: 25%;
            flex: 0 0 25%;
        max-width: 25%;
    }
    .col-print-2 {
            width: 16.66666667%;
            flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    }
    .col-print-1 {
            width: 8.33333333%;
            flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
}